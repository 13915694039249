import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthserviceService } from '../../services/authservice.service';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  //styleUrl: '.././login.component.css'
})

export class LoginComponent implements OnInit {
  form!: FormGroup;
  successmsg: any;
  errmsg: any;
  constructor(private Userservice: AuthserviceService, public globalService: GlobalService) { }

  ngOnInit() {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      grant_type: new FormControl('password'),
    });
  }

  onSubmit() {
    this.Userservice.postData(this.form.value)
      .subscribe((res:
        {
          status: string | number;
          body: { access_token: string; };
          statusText: string;
        }) => {

        //this.globalService.isAuthenticated = false;
        if (res.status === 200) {
          this.successmsg = 'token - ' + res.body.access_token;
          localStorage.setItem('access_token', res.body.access_token);
          this.globalService.isAuthenticated = true;
        } else {
          this.errmsg = res.status + ' - ' + res.statusText;
        }
      },
        (
          err: { status: number; }) => {

          this.globalService.isAuthenticated = false;

          if (err.status === 401) {
            this.errmsg = 'Invalid username or password.';
          }
          else if (err.status === 400) {
            this.errmsg = 'Invalid username or password.';
          }
          else {
            this.errmsg = "Invalid username or password";
          }
        });
  }
}
