import { Component } from '@angular/core';

@Component({
  selector: 'app-cardpayment',
  templateUrl: './cardpayment.component.html',
  styleUrl: './cardpayment.component.css'
})

export class CardpaymentComponent {
  successmsg: any;
  failedmsg: any;

  ngOnInit() {
    this.successmsg = 'Order placed! You will receive an email confirmation.';
    this.failedmsg = 'Order canceled -- continue to shop around and checkout when you are ready.';
  }
    //square api key- 3e59e4bc-4abe-41e1-bea2-9f102f8e2ced
    //stripe auth backup dood-vnlm-bygk-glih-myhi

  constructor() { };



}
