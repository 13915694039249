<!--<nav class="navbar navbar-dark bg-primary">
  <div class="container-fluid">
    <a routerLink="/" class="navbar-brand" style="color: white;">ScottMo.Dev</a>

    <div id="navbarText">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" style="color: white;" aria-current="page" routerLink="/home">Home</a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item">
          <a class="nav-link" style="color: white;" routerLink="/weather">Weather</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown link
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
      </ul>
      <span class="navbar-text" style="color: white;">
        Navbar text with an inline element
      </span>
    </div>

    <div *ngIf="globalService.isAuthenticated">
      <form class="d-flex" role="search">
        <app-userinfo></app-userinfo>
        <button class="btn btn-outline-light me-2" type="button" (click)="signout()">Sign out</button>
      </form>
    </div>

    <div *ngIf="!globalService.isAuthenticated">
      <div class="text-end">
        <form class="d-flex" role="search">
          <app-userinfo></app-userinfo>
          <button routerLink="/login" type="button" class="btn btn-outline-light me-2">Login</button>
          <button routerLink="/register" type="button" class="btn btn-warning">Sign-up</button>
        </form>
      </div>
    </div>

  </div>
</nav>-->
<nav class="navbar navbar-expand-lg bg-primary">
  <div class="container-fluid">
    <a routerLink="/" class="navbar-brand" style="color: white;">ScottMo.Dev</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <!--<a class="nav-link active" aria-current="page" href="#">Home</a>-->
          <a class="nav-link active" style="color: white;" aria-current="page" routerLink="/home">Home</a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item">
          <a class="nav-link" style="color: white;" routerLink="/weather">Videos</a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item">
          <a class="nav-link" style="color: white;" routerLink="/cardpayment">Payment</a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <a href="https://gmail.com" target="_blank">
            <img style="height: 20px;" src="/images/gmail.png" />
          </a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <a href="https://github.com" target="_blank">
            <img src="/images/gitHub.png" height="20px" alt="GitHub" />
          </a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <a href="https://chat.openai.com/" target="_blank">
            <img style="height: 20px;" src="/images/chatgpt.png" />
          </a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <a href="https://ted.com/" target="_blank">
            <img style="height: 20px;" src="/images/ted.png" />
          </a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <a href="https://youtube.com/" target="_blank">
            <img style="height: 20px;" src="/images/youtube.png" />
          </a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <a href="https://chase.com/" target="_blank">
            <img style="height: 20px;" src="/images/chase.png" />
          </a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <a href="https://lego.com/" target="_blank">
            <img style="height: 20px;" src="/images/lego.png" />
          </a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <a href="https://icons8.com/" target="_blank">
            <img style="height: 20px;" src="/images/icons8.png" />
          </a>
        </li>
        <!--<li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <form class="d-flex" role="search">
            <app-userinfo></app-userinfo>
            <button class="btn btn-outline-light me-2" type="button" (click)="signout()">Sign out</button>
          </form>
        </li>
        <li *ngIf="!globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <form class="d-flex" role="search">
            <app-userinfo></app-userinfo>
            <button routerLink="/login" type="button" class="btn btn-outline-light me-2">Login</button>
            <button routerLink="/register" type="button" class="btn btn-warning">Sign-up</button>
          </form>
        </li>-->

        <!--<li class="nav-item">
    <a class="nav-link" href="#">Pricing</a>
  </li>
  <li class="nav-item">
    <a class="nav-link disabled">Disabled</a>
  </li>-->
      </ul>
    </div>

    <div *ngIf="globalService.isAuthenticated">
      <form class="d-flex" role="search">
        <app-userinfo></app-userinfo>
        <button class="btn btn-outline-light me-2" type="button" (click)="signout()">Sign out</button>
      </form>
    </div>

    <div *ngIf="!globalService.isAuthenticated">
      <div class="text-end">
        <form class="d-flex" role="search">
          <app-userinfo></app-userinfo>
          <button routerLink="/login" type="button" class="btn btn-outline-light me-2">Login</button>
          <button routerLink="/register" type="button" class="btn btn-warning">Sign-up</button>
        </form>
      </div>
    </div>

  </div>
</nav>
<!--<nav class="navbar navbar-dark bg-primary">-->
  <!--<nav class="navbar navbar-expand-lg bg-light">-->
    <!--<div class="container-fluid">
      <a class="navbar-brand" href="#">Navbar w/ text</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">-->
            <!--<a class="nav-link active" aria-current="page" href="#">Home</a>-->
            <!--<a class="nav-link active" aria-current="page" routerLink="/home">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Features</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Pricing</a>
          </li>
        </ul>
        <span class="navbar-text">
          Navbar text with an inline element
        </span>
      </div>
    </div>
  </nav>-->
