import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { UserinfoComponent } from './components/userinfo/userinfo.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AuthInterceptor } from './shared/auth.interceptor';

import { AuthserviceService } from './services/authservice.service'
import { GlobalService } from './services/global.service';
import { MenuComponent } from './shared/menu/menu.component';
import { WeatherComponent } from './components/weather/weather.component';
import { HomeComponent } from './components/home/home.component';
import { CardpaymentComponent } from './components/payment/cardpayment/cardpayment.component';
import { CodeComponent } from './components/code/code.component';
import { ProductivitylinksComponent } from './components/productivitylinks/productivitylinks.component';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    LoginComponent,
    UserinfoComponent,
    WeatherComponent,
    CardpaymentComponent,
    CodeComponent,
    ProductivitylinksComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [
    AuthserviceService,
    GlobalService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ] 
})
export class AppModule { }
