<div *ngIf="!globalService.isAuthenticated">
  <div class="container">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4 jumbotron">
        <h1 class="h3 mb-3 fw-normal">Please sign in</h1><hr>

        <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>

    <div class="form-group">
      <label for="uname">User Name:</label>
      <div class="input-group">
        <div class="input-group-addon glyphicon glyphicon-user"></div>
        <input type="text" placeholder="username" formControlName="username" maxlength="30" class="form-control">
      </div>
      <div class="alert alert-danger" *ngIf="form.controls['username'].dirty && form.controls['username'].invalid">
        <div *ngIf="form.controls['username'].hasError('required')">Username is required</div>
      </div>
    </div>

    <div class="form-group">
      <label for="pwd">Password:</label>
      <div class="input-group">
        <div class="input-group-addon glyphicon glyphicon-lock"></div>
        <input type="password" placeholder="password" formControlName="password" class="form-control">
      </div>
      <div class="alert alert-danger" *ngIf="form.controls['password'].dirty && form.controls['password'].invalid">
        <div *ngIf="form.controls['password'].hasError('required')">Password is required</div>
        <div *ngIf="form.controls['password'].hasError('minlength')">Password must minlength 6 characters long.</div>
      </div>
    </div>
    <div class="form-group">
      <input type="submit" value="Login" class="btn btn-primary" [disabled]="form.invalid">
    </div>
    <div class="form-group">
      <div class="col-md-12 alert alert-danger" *ngIf="errmsg">{{errmsg}}</div>
    </div>

    <div class="form-group">
      <div class="col-md-12 alert alert-success" *ngIf="successmsg">{{successmsg}}</div>
    </div>

  </form>
        <!--<main class="form-signin w-100 m-auto">
          <form (ngSubmit)="onSubmit()">-->
            <!--<img class="mb-4" src="/docs/5.2/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57">
            ig-->
            <!--<h1 class="h3 mb-3 fw-normal">Please sign in</h1>

            <div class="form-floating">
              <input type="email" class="form-control" id="username" placeholder="name@example.com">
              <label for="username">Email address</label>
            </div>
            <div class="form-floating">
              <input type="password" class="form-control" id="password" placeholder="Password">
              <label for="password">Password</label>
            </div>

            <div class="checkbox mb-3">
              <label>
                <input type="checkbox" value="remember-me"> Remember me
              </label>
            </div>
            <button class="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
            <input type="submit" value="Login" class="btn btn-primary" [disabled]="form.invalid">
            <p class="mt-5 mb-3 text-muted">© 2017–2022</p>
          </form>
        </main>-->
      </div>
    </div>
  </div>
</div>
