<a href="https://gmail.com" target="_blank">
  <img style="height: 50px;" src="/images/Gmail.gif" />
</a>
<a href="https://github.com" target="_blank">
  <img src="/images/GitHub.gif" height="50px" alt="GitHub" />
</a>
<a href="https://chat.openai.com/" target="_blank">
  <img style="height: 50px;" src="/images/ChatGpt.gif" />
</a>


