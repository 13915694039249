<!--<h1 id="tableLabel">Home</h1>-->

<div *ngIf="!globalService.isAuthenticated">

  <div class="container-fluid pb-3">
    <div class="d-grid gap-3" style="grid-template-columns: 1fr 2fr;">
      <div class="bg-body-tertiary border rounded-3">
        <p style="margin-top: 20px;">“Making the simple complicated is common place; making the complicated simple, awesomely simple, that’s creativity.” – Charles Mingus</p>
      </div>
      <div class="bg-body-tertiary border rounded-3">
        <dotlottie-player src="https://lottie.host/1ae907ed-23f9-4a8f-a230-0e194e665358/sPfd00TTYp.lottie" background="transparent" speed="1" style="width: 300px; height: 300px" loop autoplay>
        </dotlottie-player>
      </div>
    </div>
  </div>

  <!--<p style="margin-top: 20px;">“Making the simple complicated is common place; making the complicated simple, awesomely simple, that’s creativity.” – Charles Mingus</p>

  <div id="artiststhumbnail">
    <dotlottie-player src="https://lottie.host/1ae907ed-23f9-4a8f-a230-0e194e665358/sPfd00TTYp.lottie" background="transparent" speed="1" style="width: 300px; height: 300px" loop autoplay>
    </dotlottie-player>
  </div>-->

</div>
<div *ngIf="globalService.isAuthenticated">

  <!--<app-productivitylinks></app-productivitylinks>
  <app-code></app-code>-->
</div>
